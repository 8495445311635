/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

$(function(){

  createHamburgerMenu();
  createAnchorScroll();
  createAccordion();
  createPageTop();
  createTab();
  replaceTelNumber();
  $('.matchHeight').matchHeight();

});

function createAnchorScroll () {
  //tab内のaタグのみ除外
  $('a[href^="#"]:not([class*="modal"])').on('click', function () {
    if( $(this).closest('[data-js="tab"]').length > 0 ) {return false;}
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
}

function replaceTelNumber () {
  //電話番号のイベントが単一の場合
  //スマホの時だけ電話ができるように
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
    //電話番号
    $('[data-js="tel"]').each(function() {
      //.tel内のHTMLを取得
      var str = $(this).html();
      //子要素がimgだった場合、alt属性を取得して電話番号リンクを追加
      if ($(this).children().is('img')) {
        $(this).html($('<a>').attr({
          href: 'tel:' + $(this).children().attr('alt').replace(/-/g, ''),
          onclick: "gtag('event', 'アクション', {'event_category': 'カテゴリ','event_label': 'ラベル'});"
        }).append(str + '</a>'));
      } else {
        //それ以外はテキストを取得して電話番号リンクを追加
        $(this).html($('<a>').attr({
          href: 'tel:' + $(this).text().replace(/-/g, ''),
          onclick: "gtag('event', 'tap', {'event_category': '電話番号','event_label': '電話番号'});"
        }).append(str + '</a>'));
      }
    });
  }
}

function createAccordion () {
  $('[data-js="accordion"] .accordion_title').on('click', function () {
    $(this).toggleClass('open');
    $('+.accordion_body',this).slideToggle();
  });
}

//  pageTop scroll
function createPageTop () {
  var $pagetop = $('a[data-js="pagetop"]');
  if ( $(window).width() > 768 ) $pagetop.hide();
  window.addEventListener('scroll', function() {
    if ( $(window).scrollTop() > 200 ) {
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  });

  $pagetop.on('click', function (e) {
    e.preventDefault();
    $('html,body').animate({scrollTop:0},1000);
    return false;
  });
}

// Tab
function createTab () {
  var t_item = $('[data-js="tab"] .tab_select .tab_item > a:not([target="_blank"])');
  var t_article = $('[data-js="tab"] .tab_body .tab_article');

  t_item.on('click', function (e) {
    e.preventDefault();
    var t_target = $(this).attr('href');
    //active付与
    $(this).parent().siblings().removeClass('active');
    $(this).parent().addClass('active');
    //show付与
    $(t_target).siblings().removeClass('show')
    $(t_target).addClass('show');
  });

  // hash付きで遷移した場合、対象のタブを表示
  var hash = location.hash;
  var t = $('[data-js="tab"] .tab_select .tab_item > a[href="'+ hash +'"]');
  var hash_target = $(hash);
  if ( hash.length ) {
    t.parent().siblings().removeClass('active');
    t.parent().addClass('active');
    hash_target.siblings().removeClass('show');
    hash_target.addClass('show');
  }

  $(window).on('resize', function(){
    //横にスライドできます表記出現条件
    $('[data-js="tab"]').each(function () {
      var tab_item = $(this).find('.tab_item');
      var totalTabWidth = 0;
      tab_item.each(function() {
        totalTabWidth += tab_item.outerWidth();
      })
      if ( totalTabWidth >= $(window).width() ) {
        $(this).addClass('add_attention');
      } else {
        $(this).removeClass('add_attention');
      }
    });
  });
}